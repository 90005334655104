import React from "react";

export const Card = ({ title, text, link }) => {
  return (
    <div className="card">
      <div className="card__image">
        <img alt="alt" src="/img/registro.png" />
      </div>
      <div className="card__desc">
        <h2 className="card__title">{title}</h2>
        <p className="card__text">{text}</p>
        <div className="card__footer">
          <a
            className="button"
            rel="noopener noreferrer"
            target="_blank"
            href={link}
          >
            Solicitar
          </a>
        </div>
      </div>
    </div>
  );
};
