import React, { useEffect } from "react";
import "../../styles/cards.css";
import { Hero } from "../../components/Hero";
import { Card } from "../../components/Card";

export const RegistroPropiedad = () => {
  const cards = [
    {
      title: "Abono de Títulos Últimos 10 Años",
      text:
        "Carpeta que contiene copia de la inscripción de una propiedad y un certificado donde se hace mención a los títulos anteriores, hasta 10 años, de una propiedad",
      link:
        "https://conservadoresdigitales.cl/conservador/copiapo/tramites-en-linea/carpeta-10-anos-sgp",
    },
    {
      title: "Abono Carpeta de Títulos Últimos 30 Años",
      text:
        "Carpeta que contiene copia de la inscripción de una propiedad y un certificado donde se hace mención a los títulos anteriores, hasta 30 años, de una propiedad.",
      link:
        "https://conservadoresdigitales.cl/conservador/copiapo/tramites-en-linea/carpeta-30-anos-sgp",
    },
    {
      title: "Certificado de Deslindes",
      text:
        "Documento que señala los deslindes de un inmueble conforme a su inscripción conservatoria o conforme a los planos archivados en el Registro de Propiedad.",
      link:
        "https://conservadoresdigitales.cl/conservador/copiapo/tramites-en-linea/certificado-deslindes",
    },
    {
      title: "Certificado de Dominio Vigente",
      text:
        "Documento que acredita quien es el actual dueño del inmueble conforme al Registro de Propiedad.",
      link:
        "https://conservadoresdigitales.cl/conservador/copiapo/tramites-en-linea/certificado-dominio-vigente",
    },
    {
      title: "Certificado de Hipotecas, Gravámenes y Prohibiciones (GP)",
      text:
        "Es un certificado que incluye los gravámenes que afectan a un bien raíz como hipotecas, servidumbres, usufructos, etc. y prohibiciones e interdicciones, como embargos, impedimentos bancarios o del Serviu para vender, entre otros.",
      link:
        "https://conservadoresdigitales.cl/conservador/copiapo/tramites-en-linea/certificado-gp",
    },
    {
      title: "Certificado Inmobiliario",
      text:
        "Certificado que contiene la individualización del inmueble, certificados de deslindes, propietarios vigentes, vigencias, formación de títulos de diez años, gravámenes y prohibiciones, bien familiar, litigios y repertorio.",
      link:
        "https://conservadoresdigitales.cl/conservador/copiapo/tramites-en-linea/certificado-inmobiliario",
    },
    {
      title: "Certificado Prenda Agraria",
      text:
        "Certificado que tiene por objeto acreditar la vigencia de prenda y prohibiciones sobre una cosa mueble, para caucionar obligaciones contraídas, con anterioridad a la vigencia de la ley 20.190.",
      link:
        "https://conservadoresdigitales.cl/conservador/copiapo/tramites-en-linea/certificado-prenda-agraria",
    },
    {
      title: "Certificado Prenda Industrial",
      text:
        "Certificado que tiene por objeto acreditar la vigencia de prenda y prohibiciones sobre una cosa mueble, para caucionar obligaciones contraídas, con anterioridad a la vigencia de la ley 20.190.",
      link:
        "https://conservadoresdigitales.cl/conservador/copiapo/tramites-en-linea/certificado-prenda-industrial",
    },
    {
      title: "Copia con Vigencia del Registro de Propiedad",
      text:
        "Documento que es una copia autorizada de la inscripción de una propiedad que acredita quién es el dueño o si tiene derechos sobre el inmueble (casa, departamento o sitio).",
      link:
        "https://conservadoresdigitales.cl/conservador/copiapo/tramites-en-linea/copia-con-vigencia-propiedad",
    },
    {
      title: "Copia de Hipoteca",
      text:
        "Es una copia autorizada de la inscripción de Hipoteca. Habitualmente se requiere como complemento del estudio de título.",
      link:
        "https://conservadoresdigitales.cl/conservador/copiapo/tramites-en-linea/copia-hipoteca",
    },
    {
      title: "Copia de Prohibiciones",
      text:
        "Es una copia autorizada de la inscripción de Prohibiciones. Habitualmente se requiere como complemento del estudio de título.",
      link:
        "https://conservadoresdigitales.cl/conservador/copiapo/tramites-en-linea/copia-prohibicion",
    },
    {
      title: "Copia autorizada del Registro de Propiedad",
      text:
        "Es una copia autorizada de la inscripción de una propiedad. Habitualmente se requiere para obtener una copia de un titulo anterior, posesion efectiva, testamento, etc.",
      link:
        "https://conservadoresdigitales.cl/conservador/copiapo/tramites-en-linea/copia-sin-vigencia-propiedad",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="animate__animated animate__fadeIn">
      <Hero
        title="Registro de Propiedad, Hipotecas y Prohibiciones"
        desc="Solicite certificados y copias."
      />
      <div className="container">
        <div className="cards">
          {cards.map(({ title, text, link }, i) => (
            <Card key={i} title={title} text={text} link={link} />
          ))}
        </div>
      </div>
    </div>
  );
};
