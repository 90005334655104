import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Hero } from "../components/Hero";

export const Tramites = ({ title, desc }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Hero title={title} desc={desc} />
      <div className="wrap accesos animate__animated animate__fadeIn animate__delay-1s">
        <Link to="/registro-propiedad">
          <div className="acceso">
            <div className="acceso__header">
              <i className="fas fa-home"></i>
              <div className="acceso__header--desc">
                <p>Autorizada-Copia con Vigencia</p>
                <p>Gravamen Proh. Lit. y Bien Familia</p>
                <p>Certificado Dominio Vigente</p>
                <p>entre otros...</p>
              </div>
            </div>
            <div className="acceso__body">
              <p>Registro de Propiedad, Hipotecas y Prohibiciones</p>
            </div>
            <div className="acceso__footer">
              <small>Tramites en Línea</small>
            </div>
          </div>
        </Link>
        <Link to="/registro-comercio">
          <div className="acceso">
            <div className="acceso__header">
              <i className="fas fa-building"></i>
              <div className="acceso__header--desc">
                <p>Copia Autorizada-Copia con Vigencia</p>
                <p>Certificado de capital para publicacion en diario oficial</p>
                <p>
                  Certificado de vigencia de Sociedad del Registro de Comercio.
                </p>
              </div>
            </div>
            <div className="acceso__body">
              <p>Registro de Comercio</p>
            </div>
            <div className="acceso__footer">
              <small>Tramites en Línea</small>
            </div>
          </div>
        </Link>
        <Link to="/registro-aguas">
          <div className="acceso">
            <div className="acceso__header">
              <i className="fas fa-tint"></i>
              <div className="acceso__header--desc">
                <p>Copia Autorizada-Copia con Vigencia</p>
                <p>Cert. Gravamen Proh. Lit. y Bien Familia</p>
                <p>Certificado de Representante legal</p>
              </div>
            </div>
            <div className="acceso__body">
              <p>Registro de Aguas</p>
            </div>
            <div className="acceso__footer">
              <small>Tramites en Línea</small>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};
