import React from "react";
import { Modal, Button, Table } from "react-bootstrap";
import moment from "moment";
import "moment/locale/es";
import styled from "styled-components";
moment.locale("es");

export const ModalComponent = (props) => {
  const { caratula, loading } = props.state;

  const Finalizado = styled.span`
    background: green;
    color: #fff;
    background-color: #28a745;
    padding: 0.2em 0.6em;
    border-radius: 10rem;
  `;

  const EnProceso = styled(Finalizado)`
    color: #212529;
    background-color: #ffc107;
  `;
  const Iniciado = styled(Finalizado)`
    color: #fff;
    background-color: #007bff;
  `;

  const estado = () => {
    switch (caratula.estado) {
      case "REVISION":
        return <Iniciado>Iniciado</Iniciado>;
      case "REVISION DE CERTIFICADOS":
        return <Iniciado>Iniciado</Iniciado>;
      case "TITULACION":
        return "Proceso de copias";
      case "VALORACION ESTUDIO":
        return "Valorizando copias";
      case "KARDEX":
        return <Finalizado>Finalizado</Finalizado>;
      case "FINALIZADO":
        return <Finalizado>Finalizado</Finalizado>;
      case "CONFORME COPIAS":
        return "En proceso de firma";
      case "CONFORME GP":
        return "En proceso de firma";
      case "CONFORME COMERCIO":
        return "En proceso de firma";
      case "GP":
        return <EnProceso>En proceso</EnProceso>;
      case "COPIAS":
        return <EnProceso>En proceso</EnProceso>;
      case "COMERCIO":
        return <EnProceso>En proceso</EnProceso>;
      case "ABOGADO REVISOR":
        return <EnProceso>EN PROCESO DE REVISION</EnProceso>;
      case "ABOGADOS":
        return <EnProceso>EN PROCESO DE REVISION</EnProceso>;
      case "ACTUALIZACION":
        return <EnProceso>EN PROCESO</EnProceso>;
      case "ACTUALIZACION Y COPIAS":
        return <EnProceso>EN PROCESO</EnProceso>;
      case "ARCHIVO JUDICIAL":
        return <EnProceso>EN PROCESO</EnProceso>;
      case "ASISTENTE":
        return <EnProceso>EN PROCESO</EnProceso>;
      case "CONFORME OFICINA JUDICIAL VIRTUAL":
        return <Finalizado>Finalizado</Finalizado>;
      case "DESPACHADO / CHILEXPRESS":
        return <Finalizado>ENVIADO A CHILEXPRESS</Finalizado>;
      case "DEVOLUCION":
        return <EnProceso>EN PROCESO DE DEVOLUCION</EnProceso>;
      case "RECHAZADO":
        return "RECHAZADO";
      case "CONFORME":
        return "EN PROCESO DE FIRMA";
      case "CONFORME SIN COPIA":
        return <EnProceso>En proceso</EnProceso>;
      case "PRESUNTIVA":
        // return "En caso de dudas sobre los reparos informados, comuníquese con nosotros en el chat de nuestro sitio web, al correo electrónico consultas@conservadorcopiapo.cl, o a nuestro teléfono (052) 2352620";
        return <EnProceso>En reparo</EnProceso>
      case "PRESUNTIVA C/RETIRO":
        // return "Dirígete a nuestra oficina o enviar un correo a consultas@conservadorcopiapo.cl indicando número de carátula";
        return <EnProceso>En reparo</EnProceso>
      case "DESPACHADO":
        return <Finalizado>DESPACHADO</Finalizado>;
      default:
        return <EnProceso>En proceso</EnProceso>;
    }
  };

  return (
    <Modal
      {...props}
      // animation={false}
      // className={`animate__animated ${
      //   props.show ? "animate__fadeIn" : "animate__fadeOut"
      // } `}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Estado Caratula
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="animate__animated animate__fadeIn">
        {loading ? (
          "Cargando..."
        ) : caratula.mensaje ? (
          "No existe la carátula"
        ) : (
          <Table>
            <tbody>
              <tr>
                <td style={{ border: "none" }}>
                  <b>Caratula</b>
                </td>
                <td style={{ border: "none" }}>{caratula.numero}</td>
              </tr>
              <tr>
                <td>
                  <b>Requirente</b>
                </td>
                <td>{caratula.nombre}</td>
              </tr>
              <tr>
                <td>
                  <b>Fecha de ingreso</b>
                </td>
                <td>{moment(caratula.fecha_i).format("DD MMMM YYYY")}</td>
              </tr>
              <tr>
                <td>
                  <b>Fecha de entrega</b>
                </td>
                <td>
                  {caratula.fecha_f
                    ? caratula.fecha_f === "0000-00-00"
                      ? "-"
                      : moment(caratula.fecha_f).format("DD MMMM YYYY")
                    : "-"}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Fecha de reingreso</b>
                </td>
                <td>
                  {caratula.fecha_reingreso
                    ? moment(caratula.fecha_reingreso).format("DD MMMM YYYY")
                    : "-"}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Estado</b>
                </td>
                <td>
                  {estado()} <br /><br />
                  {(caratula.estado === "PRESUNTIVA" || caratula.estado === "PRESUNTIVA C/RETIRO") && "En caso de dudas sobre los reparos informados, a través del chat de nuestro sitio web o al correo electrónico contacto@conservadorcopiapo.cl"}
                  {caratula.estado === "RECHAZADO" && "Comuníquese al correo electrónico web@conservadorcopiapo.cl"}
                </td>
              </tr>
            </tbody>
          </Table>
        )}
      </Modal.Body>

      <Modal.Footer>
        <small>
          *El estado de la carátula se actualizará diariamente a las 17:00 hrs.
        </small>
        <Button onClick={props.onHide}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  );
};
