import React from "react";

export const ImgComp = ({ src }) => {
  let imgStyle = {
    width: 100 + "%",
    paddingBottom: 56.25 + "%",
    backgroundImage: `url(${src})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return <div alt="slide-img" style={imgStyle} />;
};
