import React, { useState } from "react";
import { Hero } from "../components/Hero";
import "../styles/contacto.css";
import axios from "axios";

const Contacto = () => {
  const frmContact = {
    nombre: "",
    email: "",
    rut: "",
    circunscripcion: "",
    mesa: "",
    local: "",
    celular: "",
    excusa: "",
  };
  const [contact, setContact] = useState(frmContact);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(null);

  const [selectFile, setSelectFile] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("nombre", contact.nombre);
    formData.append("rut", contact.rut);
    formData.append("local", contact.local);
    formData.append("excusa", contact.excusa);
    formData.append("mesa", contact.mesa);
    formData.append("celular", contact.celular);
    formData.append("email", contact.email);
    formData.append("circunscripcion", contact.circunscripcion);

    formData.append("file", selectFile);
    setLoading(true);

    axios({
      method: "post",
      // url: "http://localhost/api/mail/",
      url: "https://www.conservadorcopiapo.cl/api/mail/",
      data: formData,
      // headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        setShowMessage(res.data);
        console.log(res);
        setLoading(false);
        setContact(frmContact);
        setSelectFile(null);
      })
      .catch((err) => {
        console.log(err);
        setShowMessage(err);
        setLoading(false);
      });
  };

  return (
    <>
      <Hero title="Datos Electorales" />

      <div className="wrap pt-2 text-center">
        <div className="alert alert-light" role="alert"></div>

        {!showMessage ? (
          ""
        ) : showMessage.sent ? (
          <div className="alert alert-success col-md-5 mx-auto" role="alert">
            {showMessage.message}
          </div>
        ) : (
          <div className="alert alert-danger col-md-5 mx-auto" role="alert">
            {showMessage.message}
          </div>
        )}

        <form className="form" onSubmit={handleSubmit}>
          <div className="form__content">
            <label>Nombre completo</label>
            <input
              required
              type="text"
              value={contact.nombre}
              name="nombre"
              onChange={handleChange}
              className="form-control"
              placeholder="Escribe tu nombre"
            />
          </div>
          <div className="form__content">
            <label>Rut</label>
            <input
              required
              type="text"
              value={contact.rut}
              name="rut"
              onChange={handleChange}
              className="form-control"
              placeholder="9.999.999-9"
            />
          </div>
          <div className="form__content">
            <label>Circunscripción:</label>
            <input
              required
              type="text"
              value={contact.circunscripcion}
              name="circunscripcion"
              onChange={handleChange}
              className="form-control"
              placeholder="Ciudad"
            />
          </div>
          <div className="form__content">
            <label>Mesa:</label>
            <input
              required
              type="text"
              value={contact.mesa}
              name="mesa"
              onChange={handleChange}
              className="form-control"
              placeholder="N° de mesa"
            />
          </div>
          <div className="form__content">
            <label>Local de votación:</label>
            <input
              required
              type="text"
              value={contact.local}
              name="local"
              onChange={handleChange}
              className="form-control"
              placeholder="Establecimiento"
            />
          </div>
          <div className="form__content">
            <label>Telefono / Celular</label>
            <input
              required
              type="text"
              value={contact.celular}
              name="celular"
              onChange={handleChange}
              className="form-control"
              placeholder="Escribe tu numero de contacto"
            />
          </div>
          <div className="form__content">
            <label>Email</label>
            <input
              required
              type="email"
              value={contact.email}
              name="email"
              onChange={handleChange}
              className="form-control"
              placeholder="Escribe tu Email"
            />
          </div>
          <div className="form__content">
            <label>Excusa</label>
            <textarea
              required
              name="excusa"
              onChange={handleChange}
              className="form-control"
              placeholder="Mi motivo es..."
              value={contact.excusa}
            ></textarea>
          </div>
          <div className="form__content">
            <label>Adjuntar documentacioón</label>
            <input
              type="file"
              name="file"
              className="form-control"
              onChange={(e) => setSelectFile(e.target.files[0])}
            />
          </div>
          <div className="">
            <button className="btn btn-primary" disabled={loading}>
              {loading ? (
                <>
                  <div class="spinner-border text-light" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  Cargando...
                </>
              ) : (
                "Enviar"
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
export default Contacto;
