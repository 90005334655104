import React from "react";
import { Link, NavLink } from "react-router-dom";
import "../styles/components/footer.css";

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="wrap">
        <div className="footer__info">
          <h2>Contacto</h2>
          <ul>
            <li>
              <i className="fas fa-map-marker-alt"></i>
              Los Carrera 256, Copiapó.
            </li>
            <li>
              <i className="fas fa-phone-alt"></i>
              Teléfono: +56 52 2352620
            </li>
            <li>
              <i className="fas fa-envelope"></i>
              Correo: consultas@conservadorcopiapo.cl
            </li>
            <li>
              <i className="far fa-calendar-alt"></i>
              Lunes a Viernes de 9:00 a 16:00.
            </li>
          </ul>
        </div>
        <div className="footer__menu">
          <h2>Ubicación</h2>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3543.4181499278106!2d-70.3391025847537!3d-27.36265351871016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9698038a90218ef9%3A0x8f403ee0dfd8d03a!2sLos+Carrera+256%2C+Copiap%C3%B3%2C+III+Regi%C3%B3n!5e0!3m2!1ses!2scl!4v1469055551800"
            width="400"
            height="300"
            frameBorder="0"
            allowFullScreen
          ></iframe>
          {/* <ul>
            <li>
              <Link to="/">Inicio</Link>
            </li>
            <li>
              <Link to="/tramites-en-linea">Tramites en Línea</Link>
            </li>
            <li>
              <Link to="/informacion">Información CBRC</Link>
            </li>
          </ul> */}
        </div>
        <div className="footer__logo">
          <img alt="logo" src="./img/logo-blanco.png" />
        </div>
        {/*
        <div className="footer__logo">
          <img alt="logo" src="./img/logoblanco.jpg" />
        </div>
        */}
        <div className="footer__logo">
          <img alt="logo" src="./img/logo3.jpg" />
        </div>
      </div>
    </footer>
  );
};
