import React, { useReducer } from "react";

import "./App.css";

import { AppRouter } from "./routers/AppRouter";

import { CheckContext } from "./check/CheckContext";
import { checkReducer } from "./check/checkReducer";

function App() {
  const [checked, dispatch] = useReducer(checkReducer, false);

  return (
    <div className="App">
      <CheckContext.Provider value={{ checked, dispatch }}>
        <AppRouter />
      </CheckContext.Provider>
    </div>
  );
}

export default App;
