import React, { useState, useEffect } from 'react'
import axios from 'axios';

import { Hero } from '../../components/Hero';



export const ArchiveroJudicial = () => {

  const frmContact = {
    notary_name: '',
    parties: '',
    date: '',
    repertorio:'',
    validity_type: 'Copia autorizada',
    number_plano: '',
    year_plano: '',
    number_file: '',
    year_file: '',
    cause_number: '',
    court: '',
    parties_court: '',
    number_legajo: '',
    trade_number: '',
    tax_number: '',
    name: '',
    address: '',
    email: '',
    cellphone: '',
    tax_number_ticket: '',
    name_ticket: '',
    address_ticket: '',
    email_ticket: '',
    cellphone_ticket: '',
  };

  const checkboxes = {
    escritura_publica: false,
    archivo_plano: false,
    documento_archivado: false,
    copia_expediente: false
  }

  const [contact, setContact] = useState(frmContact);
  const [checkBox, setCheckBox] = useState(checkboxes);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };

  const handleChangeCheck = (e) => {
    const { name, checked } = e.target;
    setCheckBox({ ...checkBox, [name]: checked })
    console.log(checkBox)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();

    formData.append("notary_name", contact.notary_name);
    formData.append("parties", contact.parties);
    formData.append("date", contact.date);
    formData.append("repertorio", contact.repertorio);
    formData.append("validity_type", contact.validity_type);
    formData.append("number_plano", contact.number_plano);
    formData.append("year_plano", contact.year_plano);
    formData.append("number_file", contact.number_file);
    formData.append("year_file", contact.year_file);
    formData.append("cause_number", contact.cause_number);
    formData.append("court", contact.court);
    formData.append("parties_court", contact.parties_court);
    formData.append("number_legajo", contact.number_legajo);
    formData.append("trade_number", contact.trade_number);
    formData.append("tax_number", contact.tax_number);
    formData.append("name", contact.name);
    formData.append("address", contact.address);
    formData.append("email", contact.email);
    formData.append("cellphone", contact.cellphone);
    formData.append("tax_number_ticket", contact.tax_number_ticket);
    formData.append("name_ticket", contact.name_ticket);
    formData.append("address_ticket", contact.address_ticket);
    formData.append("email_ticket", contact.email_ticket);
    formData.append("cellphone_ticket", contact.cellphone_ticket);

    formData.append("escritura_publica", checkBox.escritura_publica);
    formData.append("archivo_plano", checkBox.archivo_plano);
    formData.append("documento_archivado", checkBox.documento_archivado);
    formData.append("copia_expediente", checkBox.copia_expediente);

    axios({
      method: "post",
      // url: "http://localhost/api/mail/",
      url: "https://www.conservadorcopiapo.cl/api/archivero_judicial/",
      data: formData,
      headers: { "Access-Control-Allow-Origin": "*" }
      // headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        setShowMessage(res.data);
        console.log(res);
        setLoading(false);
        setContact(frmContact);
      })
      .catch((err) => {
        console.log(err);
        setShowMessage(err);
        setLoading(false);
      });
  }

  return (
    <div className="animate__animated animate__fadeIn">
      <Hero
        title="Archivero Judicial"
        desc="Solicite escritura pública, archivos de planos, documentos archivados, copia de expediente"
      />
      <div className="container archiver-judicial">
        <h2 style={{ textAlign: "left", fontSize: "22px", marginBottom:"1em" }}>Tipo de solicitud</h2>
        <p style={{ textAlign: "left"}}>Seleccione el tipo de tramite.</p>
        <div className="text-left" style={{ display: "flex"}}>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" checked={checkBox.escritura_publica} id="escritura_publica" name="escritura_publica" onChange={handleChangeCheck} />
            <label className="form-check-label" htmlFor="escritura_publica">
              Escritura pública
            </label>
          </div>

          <div className="form-check">
            <input className="form-check-input" type="checkbox" checked={checkBox.archivo_plano} id="archivo_plano" name="archivo_plano" onChange={handleChangeCheck} />
            <label className="form-check-label" htmlFor="archivo_plano">
              Archivo plano
            </label>
          </div>

          <div className="form-check">
            <input className="form-check-input" type="checkbox" checked={checkBox.documento_archivado} id="documento_archivado" name="documento_archivado" onChange={handleChangeCheck} />
            <label className="form-check-label" htmlFor="documento_archivado">
              Documento archivado
            </label>
          </div>

          <div className="form-check">
            <input className="form-check-input" type="checkbox" checked={checkBox.copia_expediente} id="copia_expediente" name="copia_expediente" onChange={handleChangeCheck} />
            <label className="form-check-label" htmlFor="copia_expediente">
              Copia de expediente
            </label>
          </div>

        </div>
        <div className="wrap pt-2 text-center">
          <div className="alert alert-light" role="alert"></div>

          {!showMessage ? (
            ""
          ) : showMessage.sent ? (
            <div className="alert alert-success col-md-5 mx-auto" role="alert">
              {showMessage.message}
            </div>
          ) : (
            <div className="alert alert-danger col-md-5 mx-auto" role="alert">
              {showMessage.message}
            </div>
          )}

          <form style={{ textAlign: "left", marginBottom: "2em"}} onSubmit={handleSubmit}>
            {checkBox.escritura_publica && (
              <div className="form-escritura-publica">
                <h3 style={{ fontSize: "18px"}}>Escritura pública</h3>
                <div className="form">
                  <div className="form__content">
                    <label>Nombre notario*</label>
                    <input
                      required={checkBox.escritura_publica}
                      type="text"
                      value={contact.notary_name}
                      name="notary_name"
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>

                  <div className="form__content">
                    <label>Partes comparecientes</label>
                    <input
                      required={checkBox.escritura_publica}
                      type="text"
                      value={contact.parties}
                      name="parties"
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                  
                  <div className="form__content">
                    <label>Repertorio:</label>
                    <input
                      required={checkBox.escritura_publica}
                      type="text"
                      value={contact.repertorio}
                      name="repertorio"
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                  
                  <div className="form__content">
                    <label>Fecha:</label>
                    <input
                      required={checkBox.escritura_publica}
                      type="date"
                      value={contact.date}
                      name="date"
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>

                  <div className="form__content">
                    <label>Tipo vigencia</label>
                    <select className="form-control form-select" aria-label="Default select example" onChange={handleChange} name="validity_type" value={contact.validity_type}>
                      <option value="Copia autorizada">Copia autorizada</option>
                      <option value="Copia vigente">Copia vigente</option>
                    </select>
                  </div>
                </div>
              </div>
            )}
            {checkBox.archivo_plano && (
              <div className="form-escritura-publica">
                <h3 style={{ fontSize: "18px"}}>Archivo plano</h3>
                <div className="form">
                  <div className="form__content">
                    <label>Numero de plano*</label>
                    <input
                      required={checkBox.archivo_plano}
                      type="text"
                      value={contact.number_plano}
                      name="number_plano"
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>

                  <div className="form__content">
                    <label>Año*</label>
                    <input
                      required={checkBox.archivo_plano}
                      type="text"
                      value={contact.year_plano}
                      name="year_plano"
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            )}
            {checkBox.documento_archivado && (
              <div className="form-escritura-publica">
                <h3 style={{ fontSize: "18px"}}>Documento archivado</h3>
                <div className="form">
                  <div className="form__content">
                    <label>Numero de archivo*</label>
                    <input
                      required={checkBox.documento_archivado}
                      type="text"
                      value={contact.number_file}
                      name="number_file"
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>

                  <div className="form__content">
                    <label>Año*</label>
                    <input
                      required={checkBox.documento_archivado}
                      type="text"
                      value={contact.year_file}
                      name="year_file"
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            )}
            {checkBox.copia_expediente && (
              <div className="form-requiriente">
                <h3 style={{ fontSize: "18px"}}>Copia expediente</h3>
                <div className="form">
                  <div className="form__content">
                    <label>Numero de causa*</label>
                    <input
                      required={checkBox.copia_expediente}
                      type="text"
                      value={contact.cause_number}
                      name="cause_number"
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>

                  <div className="form__content">
                    <label>Tribunal*</label>
                    <input
                      required={checkBox.copia_expediente}
                      type="text"
                      value={contact.court}
                      name="court"
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Escribe el nombre del tribunal"
                    />
                  </div>
                  
                  <div className="form__content">
                    <label>Las partes:</label>
                    <input
                      required={checkBox.copia_expediente}
                      type="text"
                      value={contact.parties_court}
                      name="parties_court"
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>

                  <div className="form__content">
                    <label>Numero de legajo</label>
                    <input
                      required={checkBox.copia_expediente}
                      type="text"
                      value={contact.number_legajo}
                      name="number_legajo"
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>

                  <div className="form__content">
                    <label>Numero de oficio y fecha*</label>
                    <input
                      required={checkBox.copia_expediente}
                      type="text"
                      value={contact.trade_number}
                      name="trade_number"
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="form-requiriente">
              <h3 style={{ fontSize: "18px"}}>Datos requiriente</h3>
              <div className="form">
                <div className="form__content">
                  <label>Rut*</label>
                  <input
                    required
                    type="text"
                    value={contact.tax_number}
                    name="tax_number"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="9.999.999-9"
                  />
                </div>

                <div className="form__content">
                  <label>Nombre completo*</label>
                  <input
                    required
                    type="text"
                    value={contact.name}
                    name="name"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Escribe tu nombre"
                  />
                </div>
                
                <div className="form__content">
                  <label>Dirección:</label>
                  <input
                    required
                    type="text"
                    value={contact.address}
                    name="address"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Ciudad"
                  />
                </div>

                <div className="form__content">
                  <label>Email</label>
                  <input
                    required
                    type="email"
                    value={contact.email}
                    name="email"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Escribe tu Email"
                  />
                </div>

                <div className="form__content">
                  <label>Telefono / Celular</label>
                  <input
                    required
                    type="text"
                    value={contact.cellphone}
                    name="cellphone"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Escribe tu numero de contacto"
                  />
                </div>
              </div>
            </div>
            <div className="form-requiriente">
              <h3 style={{ fontSize: "18px"}}>Datos boleta</h3>
              <div className="form">
                <div className="form__content">
                  <label>Rut*</label>
                  <input
                    required
                    type="text"
                    value={contact.tax_number_ticket}
                    name="tax_number_ticket"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="9.999.999-9"
                  />
                </div>

                <div className="form__content">
                  <label>Nombre completo*</label>
                  <input
                    required
                    type="text"
                    value={contact.name_ticket}
                    name="name_ticket"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Escribe tu nombre"
                  />
                </div>
                
                <div className="form__content">
                  <label>Dirección:</label>
                  <input
                    required
                    type="text"
                    value={contact.address_ticket}
                    name="address_ticket"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Ciudad"
                  />
                </div>

                <div className="form__content">
                  <label>Email</label>
                  <input
                    required
                    type="email"
                    value={contact.email_ticket}
                    name="email_ticket"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Escribe tu Email"
                  />
                </div>

                <div className="form__content">
                  <label>Telefono / Celular</label>
                  <input
                    required
                    type="text"
                    value={contact.cellphone_ticket}
                    name="cellphone_ticket"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Escribe tu numero de contacto"
                  />
                </div>
              </div>
            </div>
            {console.log(loading || !checkBox.escritura_publica)}
            <div style={{ textAlign: "center" }}>
              <button className="btn btn-primary" style={{ padding: '0.5em 2em' }} disabled={loading}>
                {loading ? (
                  <>
                    <div className="spinner-border text-light" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    Cargando...
                  </>
                ) : (
                  "Enviar"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
