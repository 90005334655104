import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/components/header1.css";
import { Menu } from "./Menu";
import { Burger } from "./Burger";

export const Header = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className="wrap">
      <header className="header">
        <Link to="/" style={{ zIndex: 30 }}>
          <img
            style={{ zIndex: 10000 }}
            alt="logo"
            className="header__logo"
            src="./img/logo.png"
          />
        </Link>
        <Burger open={open} setOpen={setOpen} />

        {/* <nav className="header__nav" style={{ zIndex: 10000 }}>
          <input type="checkbox" id="menu" className="header__menu--checkbox" />
          <ul className="header__menu">
            <li>
              <NavLink exact activeClassName="active" to="/">
                Inicio
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/tramites-en-linea">
                Tramites en Línea
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/consultas-en-linea">
                Consultas en Línea
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/informacion">
                Información CBRC
              </NavLink>
            </li>
          </ul>
          <label htmlFor="menu" className="header__menu--button"></label>
        </nav> */}
      </header>
      <Menu open={open} setOpen={setOpen} />
    </div>
  );
};
