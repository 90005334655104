import React, { useEffect } from "react";
import { Card } from "../../components/Card";
import { Hero } from "../../components/Hero";

export const RegistroAguas = () => {
  const cards = [
    {
      title:
        "Certificado de Hipotecas, Gravámenes y Prohibiciones de Aguas (GP)",
      text:
        "Es un certificado que incluye los gravámenes que afectan los derechos de aprovechamiento de aguas, tales como hipotecas, usufructos o algún tipo de prohibición  y también a embargos o prohibiciones derivadas de operaciones bancarias.",
      link:
        "https://conservadoresdigitales.cl/conservador/copiapo/tramites-en-linea/certificado-gp-aguas",
    },
    {
      title: "Certificado de Representante Legal",
      text:
        "Documento que certifica que no hay nota o subinscripción al margen de la inscripción en que se haya otorgado poder a un mandatario, representante o gerente, de que se le haya revocado.",
      link:
        "https://conservadoresdigitales.cl/conservador/copiapo/tramites-en-linea/certificado-representante-legal",
    },
    {
      title: "Copia con Vigencia de Aguas",
      text:
        "Es una copia autorizada de la inscripción de Aguas. Habitualmente se requiere como complemento del estudio de título.",
      link:
        "https://conservadoresdigitales.cl/conservador/copiapo/tramites-en-linea/copia-vigencia-aguas",
    },
    {
      title: "Copia de Aguas",
      text:
        "Es una copia autorizada de cualquier inscripción del Registro de Comercio.",
      link:
        "https://conservadoresdigitales.cl/conservador/copiapo/tramites-en-linea/copia-aguas",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="animate__animated animate__fadeIn">
      <Hero
        title="Registro de Aguas"
        desc="Solicite certificados y copias de aguas"
      />
      <div className="container">
        <div className="cards">
          {cards.map(({ title, text, link }, i) => (
            <Card key={i} title={title} text={text} link={link} />
          ))}
        </div>
      </div>
    </div>
  );
};
