import React, { useEffect, useState } from "react";
import "./slider.scss";
import { ImgComp } from "./ImgComp";

const Slider = () => {
  //vamos acrear un array para que el componenteo muestre dentro del slider
  let sliderArr = [
    "/img/slider/1.jpeg",
    "/img/slider/2.jpeg",
    "/img/slider/3.jpeg",
    "/img/slider/4.jpeg",
    "/img/slider/5.jpeg",
    "/img/slider/6.jpeg",
  ];

  const [x, setX] = useState(0);

  var interval;

  const moverSlider = () => {
    interval = setTimeout(() => goRight(), 3000);
  };

  moverSlider();

  // const goLeft = () => {
  //   setX(x + 100);
  //   x === 0 ? setX(-100 * (sliderArr.length - 1)) : setX(x + 100);
  //   clearTimeout(interval);
  // };

  const goRight = () => {
    x === -100 * (sliderArr.length - 1) ? setX(0) : setX(x - 100);
    clearTimeout(interval);
  };

  // const thumbClick = (index) => {
  //   setX(-100 * index);
  // };

  return (
    <>
      <div className="slider">
        {sliderArr.map((item, index) => {
          return (
            <div
              key={index}
              className="slide"
              style={{ transform: `translateX(${x}%)` }}
            >
              <ImgComp src={item} />
            </div>
          );
        })}
        {/*
        <button id="goLeft" onClick={goLeft}>
          <i className="fas fa-chevron-left"></i>
        </button>
        <button id="goRight" onClick={goRight}>
          <i className="fas fa-chevron-right"></i>
        </button>
         <div className="thumb">
          {sliderArr.map((item, index) => {
            return (
              <div
                key={index}
                className="thumb__content"
                onClick={() => thumbClick(index)}
                style={{
                  boxShadow:
                    x === -100 * index && "0 20px 44px rgba(0,0,0, 0.5)",
                  width: x === -100 * index && "11%",
                }}
              >
                <img src={item} alt={item} />
              </div>
            );
          })}
        </div> */}
      </div>
    </>
  );
};

export default Slider;
