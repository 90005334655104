import React, { useEffect } from "react";
import { Card } from "../../components/Card";
import { Hero } from "../../components/Hero";

export const RegistroComercio = () => {
  const cards = [
    {
      title: "Certificado de Capital para Publicación en Diario Oficial",
      text: "Es un Certificado que indica el capital actual de la sociedad.",
      link:
        "https://conservadoresdigitales.cl/conservador/copiapo/tramites-en-linea/certificado-capital",
    },
    {
      title: "Certificado de Vigencia de Sociedad del Registro de Comercio",
      text:
        "Documento que certifica que no hay nota o subinscripción al margen de la primera inscripción de que se le haya dado término a la sociedad.",
      link:
        "https://conservadoresdigitales.cl/conservador/copiapo/tramites-en-linea/cert-vigencia-sociedad",
    },
    {
      title: "Copia de Inscripción con Vigencia del Registro de Comercio",
      text:
        "Es una copia autorizada de la primera inscripción realizada en este Registro de Comercio respecto de una sociedad, la cual indica que no hay nota o subinscripción de que se le haya dado término.",
      link:
        "https://conservadoresdigitales.cl/conservador/copiapo/tramites-en-linea/copia-vigencia-comercio",
    },
    {
      title: "Copia de Inscripción sin Vigencia del Registro de Comercio",
      text:
        "Es una copia autorizada de cualquier inscripción del Registro de Comercio.",
      link:
        "https://conservadoresdigitales.cl/conservador/copiapo/tramites-en-linea/copia-sin-vigencia-comercio",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="animate__animated animate__fadeIn">
      <Hero
        title="Registro de Comercio"
        desc="Solicite certificados y copias de comercio."
      />
      <div className="container">
        <div className="cards">
          {cards.map(({ title, text, link }, i) => (
            <Card key={i} title={title} text={text} link={link} />
          ))}
        </div>
      </div>
    </div>
  );
};
