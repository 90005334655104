import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import { CheckContext } from "../check/CheckContext";
// import { checkReducer } from "../check/checkReducer";
import { Accesos } from "../components/Accesos";
import { Hero } from "../components/Hero";
import Slider from "../components/slide/Slider";
import "../styles/home.css";

export const Home = ({ title, desc }) => {
  // const { checked, dispatch } = useContext(CheckContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero title={title} desc={desc} home={true} />
      <div className="wrap">
        <Accesos />
        {/* <section className="home__nosotros">
          <h2>Sobre Nosotros</h2>
          <div className="home__items">
            <div className="home__item">
              <div className="home__item--header">
                <i className="fas fa-flag"></i>
                <h2>Misión y Visión</h2>
              </div>
              <div className="home__item--body">
                Otorgar un servicio de alto estándar a escala humana para todos
                nuestros usuarios de la...
              </div>
              <div className="home__item--footer">
                <Link to="/informacion" className="button">
                  ver más
                </Link>
              </div>
            </div>
            <div className="home__item">
              <div className="home__item--header">
                <i className="fas fa-users"></i>
                <h2>Nosotros</h2>
              </div>
              <div className="home__item--body">
                Somos una organización Auxiliar de la Administración de...
              </div>
              <div className="home__item--footer">
                <Link to="/informacion" className="button">
                  ver más
                </Link>
              </div>
            </div>

            <div className="home__item">
              <div className="home__item--header">
                <i className="fas fa-star"></i>
                <h2>Valores</h2>
              </div>
              <div className="home__item--body">
                1. PASION, en todo lo que hacemos <br /> 2. LEALTAD...
              </div>
              <div className="home__item--footer">
                <Link to="/informacion#valores" className="button">
                  ver más
                </Link>
              </div>
            </div>
            <div className="home__item">
              <div className="home__item--header">
                <i className="fas fa-desktop"></i>
                <h2>Tecnología</h2>
              </div>
              <div className="home__item--body">
                Firma Electrónica Avanzada <br /> Registros Digitales <br />{" "}
                Archivo Digital
              </div>
              <div className="home__item--footer">
                <Link to="/informacion" className="button">
                  ver más
                </Link>
              </div>
            </div>
          </div>
        </section> */}

        <div className="home__info">
          <div className="home__notif">
        <Slider />
        {/* <div className="home__icon">
              <i className="fas fa-info-circle"></i>
            </div>
            <p>
              La Atención de Público se realizará únicamente a Usuarios que
              porten Permiso Temporal Individual y Cédula de Identidad. Horario
              de Atención, Lunes a Viernes 9:00 am a 14:00pm. Por su Atención y
              comprensión Muchas Gracias
            </p> */}
          </div>
          <div className="home__mapa">
            <div className="home__mapa--img"></div>
            <div className="home__mapa--desc">
              <h2>Juridiscción</h2>
              <p>Las comunas que tenemos en jurisdicción son:</p>
              <ul>
                <li>Copiapó</li>
                <li>Tierra Amarilla</li>
              </ul>
            </div>
          </div>
          </div>
          </div>
    </>
  );
};
