import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #effffa;
  transform: ${({ open }) => (open ? "translateX(0%)" : "translateX(150%)")};
  height: 100vh;
  text-align: left;
  position: absolute;
  top: 0;
  right: 0%;
  transition: transform 0.3s ease-in-out;
  z-index: 18;
  padding: 2em;
  box-shadow: 0 0 44px rgba(0, 0, 0, 0.2);
  @media (max-width: 576px) {
    width: 100%;
    transform: ${({ open }) => (open ? "translateX(0%)" : "translateX(100%)")};
  }

  a {
    font-size: 16px;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #0d0c1d;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 16px;
      text-align: center;
    }

    &:hover {
      color: #343078;
    }
  }
`;

export const Menu = ({ open, setOpen }) => {
  return (
    <StyledMenu open={open}>
      <NavLink
        exact
        activeClassName="active"
        to="/"
        onClick={() => setOpen(!open)}
      >
        Inicio
      </NavLink>
      <NavLink exact to="/tramites-en-linea" onClick={() => setOpen(!open)}>
        Tramites en Línea
        </NavLink> 

        
      {/*
      <NavLink exact to="/datos-electorales" onClick={() => setOpen(!open)}>
        Datos Electorales
      </NavLink> 
      
      */}
      
      {/* <NavLink exact to="/informacion" onClick={() => setOpen(!open)}>
        Información CBRC
      </NavLink> */}
    </StyledMenu>
  );
};
