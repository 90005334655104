import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "../hooks/useForm";

export const Accesos = () => {
  const [{ foja }, handleInputChange] = useForm({
    foja: "",
  });
  return (
    <div className="accesos animate__animated animate__fadeIn animate__delay-1s">
      <Link to="/registro-propiedad">
        <div className="acceso">
          <div className="acceso__header">
            <i className="fas fa-home"></i>
            <div className="acceso__header--desc">
              <p>
                <i className="fas fa-check" style={{ fontSize: "12px" }}></i>{" "}
                Copias
              </p>
              <p>
                <i className="fas fa-check" style={{ fontSize: "12px" }}></i>{" "}
                Certificados
              </p>
              <p>
                <i className="fas fa-check" style={{ fontSize: "12px" }}></i>{" "}
                Entre otros
              </p>
            </div>
          </div>
          <div className="acceso__body">
            <p>Registro de Propiedad, Hipotecas y Prohibiciones</p>
          </div>
          <div className="acceso__footer">
            <small>Tramites en Línea</small>
          </div>
        </div>
      </Link>
      <Link to="/registro-comercio">
        <div className="acceso">
          <div className="acceso__header">
            <i className="fas fa-building"></i>
            <div className="acceso__header--desc">
              <p>
                <i className="fas fa-check" style={{ fontSize: "12px" }}></i>{" "}
                Copias
              </p>
              <p>
                <i className="fas fa-check" style={{ fontSize: "12px" }}></i>{" "}
                Certificados
              </p>
            </div>
          </div>
          <div className="acceso__body">
            <p>Registro de Comercio</p>
          </div>
          <div className="acceso__footer">
            <small>Tramites en Línea</small>
          </div>
        </div>
      </Link>
      <Link to="/registro-aguas">
        <div className="acceso">
          <div className="acceso__header">
            <i className="fas fa-tint"></i>
            <div className="acceso__header--desc">
              <p>
                <i className="fas fa-check" style={{ fontSize: "12px" }}></i>{" "}
                Copias
              </p>
              <p>
                <i className="fas fa-check" style={{ fontSize: "12px" }}></i>{" "}
                Certificados
              </p>
            </div>
          </div>
          <div className="acceso__body">
            <p>Registro de Aguas</p>
          </div>
          <div className="acceso__footer">
            <small>Tramites en Línea</small>
          </div>
        </div>
      </Link>
      
      {/*
      <Link to="/archivero-judicial">
        <div className="acceso">
          <div className="acceso__header">
            <i class="fas fa-landmark"></i>
            <div className="acceso__header--desc">
              <p>
                <i className="fas fa-check" style={{ fontSize: "12px" }}></i>{" "}
                Escritura pública
              </p>
              <p>
                <i className="fas fa-check" style={{ fontSize: "12px" }}></i>{" "}
                Archivo plano
              </p>
              <p>
                <i className="fas fa-check" style={{ fontSize: "12px" }}></i>{" "}
                Documento archivado
              </p>
              <p>
                <i className="fas fa-check" style={{ fontSize: "12px" }}></i>{" "}
                Copia de expediente
              </p>
            </div>
          </div>
          <div className="acceso__body">
            <p>Archivero Judicial</p>
          </div>
          <div className="acceso__footer">
            <small>Tramites en Línea</small>
          </div>
        </div>
      </Link>

      */}

      <a
        href="https://zeroq.cl/#/local/cbr-copiapo/776?_k=c5a9g3"
        target="_blank"
      >
        <div className="acceso">
          <div className="acceso__header">
            <i className="fas fa-ticket-alt"></i>
            <div className="acceso__header--desc">
              <p>
                <i className="fas fa-check" style={{ fontSize: "12px" }}></i>{" "}
                Reserve hora. Organice y ahorre su tiempo.
              </p>
            </div>
          </div>
          <div className="acceso__body">
            <p>Ticket de Atención</p>
          </div>
          <div className="acceso__footer">
            <small>Tramites en Línea</small>
          </div>
        </div>
      </a>
      
      <div className="acceso">
        <div className="acceso__header">
          <i className="fas fa-download"></i>
          <div className="acceso__header--desc">
            <p>Entrega de documentos</p>
            <p>Validacion de documentos</p>
            <form>
              <input
                className="form-control"
                value={foja}
                onChange={handleInputChange}
                name="foja"
                placeholder="N° Certificado"
                autoComplete="off"
              />
              <a
                href={`https://www.fojas.cl/index.php?sessd=222ea4af8fc505234707e69f8339c4f9&req=31acbaa3116308fae8cd5a37605bcff7&motv=down_serv&tkn=affca&fn78werhm=${foja}&cons=cbr_copiapo`}
                target="_blank"
                className="btn btn-primary"
                style={{ marginTop: "1em" }}
              >
                Validar
              </a>
            </form>
          </div>
        </div>
        <div className="acceso__body">
          <p>Entrega y Validación</p>
        </div>
        <div className="acceso__footer">
          <small>Consulta en Línea</small>
        </div>
      </div>
     
    </div>
  );
};

// https://www.fojas.cl/index.php?sessd=222ea4af8fc505234707e69f8339c4f9&req=31acbaa3116308fae8cd5a37605bcff7&motv=down_serv&tkn=affca&fn78werhm=488126&cons=cbr_copiapo
