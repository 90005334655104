import React, { useEffect } from "react";
import { Hero } from "../components/Hero";

export const Consultas = ({ title, desc }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Hero title={title} desc={desc} />
      <div className="wrap accesos animate__animated animate__fadeIn animate__delay-1s">
        <div className="acceso">
          <div className="acceso__header">
            <i className="fas fa-download"></i>
            <div className="acceso__header--desc">
              <p>Registro de Propiedad </p>
              <p>Registro de Comercio</p>
              <p>Registro de Aguas</p>
              <p>Registro de Minas</p>
              <p>Registro de Hipotecas</p>
              <p>Registro de Prohibiciones</p>
              <p>Registro de Planos</p>
            </div>
          </div>
          <div className="acceso__body">
            <p>Entrega en Línea</p>
          </div>
          <div className="acceso__footer">
            <small>Consulta en Línea</small>
          </div>
        </div>
        <div className="acceso">
          <div className="acceso__header">
            <i className="fas fa-check"></i>
            <div className="acceso__header--desc">
              <p>Registro de Propiedad </p>
              <p>Registro de Comercio</p>
              <p>Registro de Aguas</p>
              <p>Registro de Minas</p>
              <p>Registro de Hipotecas</p>
              <p>Registro de Prohibiciones</p>
              <p>Registro de Planos</p>
            </div>
          </div>
          <div className="acceso__body">
            <p>Verificacion de documentos</p>
          </div>
          <div className="acceso__footer">
            <small>Consulta en Línea</small>
          </div>
        </div>
      </div>
    </>
  );
};
